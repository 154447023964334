import axios from 'axios';
import { useState } from 'react';
import { useRecordContext } from 'react-admin';
import { Button } from "@mui/material"

function PrintPdfButton() {
    const record = useRecordContext();
    const [loading,setLoading] = useState(false)

    const generatePdf = async () =>{
       setLoading(true)


        await axios.post(`https://www.takerscargo.com/api/waybill`,record,{responseType: 'blob'})
        .then(res =>{
            
            //Create a Blob from the PDF Stream
    const file = new Blob(
        [res.data], 
        {type: 'application/pdf'});
  //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
  //Open the URL on new Window
      window.open(fileURL);
    
        })
        .catch(error =>{
            setLoading(false)
            console.log(error.message);
            alert(error.message)
        })
        .finally(()=>setLoading(false))
    }


    return (

        
        <Button style={{backgroundColor:`#000` , marginLeft:`2%`}} disabled={loading } variant={`contained`} onClick={generatePdf}>{!loading ? `Print Waybill` : `Loading...`}</Button>

    );
}

export default PrintPdfButton;