
import React from 'react';
import { MenuItemLink, Responsive , Menu ,DashboardMenuItem} from 'react-admin';
import { AccountCircleOutlined, AddBoxOutlined, BookOutlined, DashboardOutlined, FlightTakeoffOutlined, LocalShippingOutlined, MapOutlined, NotificationsActiveOutlined, ReceiptOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const menuItems = [
    { name: 'users', text: 'Users', icon: <AccountCircleOutlined /> },
    { name: 'bookings', text: 'Bookings', icon: <BookOutlined /> },
    { name: 'flights', text: 'Flights', icon: <FlightTakeoffOutlined /> },
    { name: 'containers', text: 'Sea', icon: <LocalShippingOutlined /> },
    { name: 'bailment', text: 'Bailment', icon: <AddBoxOutlined /> },
    { name: 'notices', text: 'Notices', icon: <NotificationsActiveOutlined /> },,
    { name: 'warehouse', text: 'Warehouse', icon: <MapOutlined /> },
];
const useSidebarStyles = makeStyles({

  
});

const CustomMenu = ({ onMenuClick, logout }) => {
  const classes = useSidebarStyles();

  
  return(
  <div>
    <Menu classes={classes}>
        <DashboardMenuItem />
    { menuItems.map(item => (
      <MenuItemLink
        key={item.name}
        to={`/${item.name}`}
        primaryText={item.text}
        leftIcon={item.icon}
        onClick={onMenuClick}
      />
    ))}
    <Responsive
      small={logout}
      medium={null} 
    />
    </Menu>
  </div>
)};

export default CustomMenu;