import * as React from "react";
import { Create, Datagrid, DeleteButton, Edit, EditButton, EmailField, List, SimpleForm, TextField, TextInput } from 'react-admin';
import Tag from '../../Components/Tag';
const Title = ({ record }) => {
    return <span>Warehouse Details {record ? `#${record.id}` : ''}</span>;
};

const Filters = [
    <TextInput source="q" label="Search" alwaysOn />,
    // <SelectInput optionText="Warehouset" label="Warehouset" />

];

export const WarehouseList = props => (
    <List filters={Filters} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="fullname" />
            <TextField source="city" />
            <TextField source="country" />
            <TextField source="detailedAddress" />
            <EmailField source="email" />
            <TextField source="phone" />  
            <Tag source="active" />
            <EditButton label={false} />
            <DeleteButton label={false} />
        </Datagrid>
    </List>
);

export const WarehouseEdit = props => (
    <Edit title={<Title />} {...props}>
        <SimpleForm>

        <TextInput source="id" />
            <TextInput source="fullname" />
            <TextInput source="city" />
            <TextInput source="country" />
            <TextInput source="detailedAddress" />
            <TextInput source="email" />
            <TextInput source="phone" />  
            <Tag source="active" />

        </SimpleForm>
    </Edit>
);

export const WarehouseCreate = props => (
    <Create {...props}>
        <SimpleForm>

        <TextInput disabled initialValue={`W`+ Date.now().toString()} source="id" />
            <TextInput source="fullname" />
            <TextInput source="city" />
            <TextInput source="country" />
            <TextInput source="detailedAddress" />
            <TextInput source="email" />
            <TextInput source="phone" />  
            <Tag source="active" />
        </SimpleForm>
    </Create>
);