import red from '@material-ui/core/colors/red';
import merge from 'lodash/merge';
import { defaultTheme } from 'react-admin';

export const theme = merge({}, defaultTheme, {
  palette: {
    primary: {
      light: '#0066ff',
      // light: will be calculated from palette.primary.main,
      main: '#000000',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#000000',
      main: '#ffffff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#000000',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
  },

  overrides: {
    RaMenuItemLink: {
      root: {
        color: `#fff`,
      },
      icon: {
        color: "inherit",
      },
      active: {
        color: "#ed8b00",
      },
      hover: {
        color: "#ed8b00",
      },
    },
    RaLayout: {
      content: {
        backgroundColor: `#f2f6fc`,
        paddingTop: "35px",
      }
    },
    RaSidebar: {
      fixed: {
        backgroundColor: `#000`,
        paddingTop: `2%`
      }
    },
    drawerPaper: {

      backgroundColor: `#fff`,

      height: "100%",
      boxShadow:
        "2px 0px 1px -1px rgba(0,0,0,0.2), 1px 0px 3px 0px rgba(0,0,0,0.1)",

    },
    MuiTypography: {
      body2: {
        fontWeight: `600`
      }
    },
    RaLabeled: {
      label: {
        fontWeight: `500`
      }
    },
    MuiButton: { // override the styles of all instances of this component
      root: { // Name of the rule
        color: '#00000', // Some CSS
      },

      RaMenuItemLink: {
        root: {
          color: `#ed8b00`,
        },
        icon: {
          color: "inherit",
        },
      },
      layout: {
        backgroundColor: `red`
      }

    },
    MuiInputBase: {
      root: {
        border: `1px solid #ED8B00`,
        borderRadius: `10px`,
        background: `#f4eadc`
      },

    },
    MuiFilledInput: {
      root: {
        '&:hover': {
          backgroundColor: `#f8dab1`
        },
        border: `1px solid #ED8B00`,
        borderRadius: `10px !important`,
        background: `#f4eadc`
      },
      underline: {
        '&:before': {

          borderBottom: `none`
        },
        '&:after': {

          borderBottom: `none`
        },
        '&:hover:before': {

          borderBottom: `none`
        },
      }
    },


  },

});