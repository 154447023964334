import React from 'react';
import {Box} from '@mui/material'

function Route({record}) {
    return (
        <>
            { record?.waypoints &&  <Box  p={3} height={`fit-content`} borderRadius={5} backgroundColor={`#000`} width={`300px`} color={`#ed8b00`}>
            { 
            record?.waypoints.map(item =>
                
                (
            <Box mb={2} key={item.location}>
            <Box color={`#fff`} >{item.location}</Box>
            <small >{item.description}</small>
            {/* <small >{JSON.stringify(item.timestamp)}</small> */}
            </Box>
        )
        )
        }
            </Box>}
        </>
    );
}

export default Route;