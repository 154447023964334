import { addDays, format, subDays } from 'date-fns';
import {
    Area, AreaChart, ResponsiveContainer, Tooltip, XAxis,
    YAxis
} from 'recharts';

const lastDay = new Date();
const lastMonthDays = Array.from({ length: 30 }, (_, i) => subDays(lastDay, i));
const aMonthAgo = subDays(new Date(), 30);

// const lastDay = new Date();
// const lastMonthDays = Array.from({ length: 30 }, (_, i) => format(subDays(lastDay, i),'D/M/YYYY'));
// const aMonthAgo = subDays(new Date(), 30);

const dateFormatter = (date)  =>
    new Date(date).toLocaleDateString();

const aggregateBookingsByDay = (orders)=> 
    orders.reduce((acc, curr) => {
            const day = format(new Date(curr.creationDate), 'D/M/YYYY');
          
            
            if (!acc[day]) {
                acc[day] =0;
           
            }
            acc[day]++;
            return acc;
        },{});

const getBookingPerDay = (orders) => {
    const daysWithRevenue = aggregateBookingsByDay(orders);
    return lastMonthDays.map(date => ({
        date: date.getTime(),
        total: daysWithRevenue[format(new Date(date), 'D/M/YYYY')] || 0,
    }));
};


const MonthlyChart = ({ shipments}) => {



    if (!shipments) return null;

    return (

                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <AreaChart data={getBookingPerDay(shipments)}>
                            <defs>
                                <linearGradient
                                    id="colorUv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                >
                                    <stop
                                        offset="5%"
                                        stopColor="#ed8b00"
                                        stopOpacity={0.8}
                                    />
                                    <stop
                                        offset="95%"
                                        stopColor="#ed8b00"
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                            </defs>
                            <XAxis
                                dataKey="date"
                                name="Date"
                                type="number"
                                scale="time"
                                domain={[
                                    addDays(aMonthAgo, 1).getTime(),
                                    new Date().getTime(),
                                ]}
                                tickFormatter={dateFormatter}

                            />
                            <YAxis dataKey="total" name="Bookings"  />
                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                            <Tooltip
                                cursor={{ strokeDasharray: '3 3' }}
                                formatter={(value) =>
                                    new Intl.NumberFormat(undefined).format(value)
                                }
                                labelFormatter={(label) =>
                                    dateFormatter(label)
                                }
                            />
                            <Area
                                type="monotone"
                                dataKey="total"
                                stroke="#ed8b00"
                                strokeWidth={2}
                                fill="url(#colorUv)"
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>

    );
};



export default MonthlyChart;