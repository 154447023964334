import { makeStyles } from '@material-ui/core/styles';
import { AccountCircleOutlined, AddBoxOutlined, BookOutlined, FlightTakeoffOutlined, LocalShippingOutlined, MapOutlined, NotificationsActiveOutlined, ReceiptOutlined } from '@material-ui/icons';
import * as React from "react";
import { Admin, Layout, Resource, Sidebar } from 'react-admin';
import { authProvider, dataProvider } from '../src/Firebase/firebase';
import { BailmentCreate, BailmentEdit, BailmentList } from '../src/Modules/Bailments/Bailments';
import { ContainersCreate, ContainersEdit, ContainerShow, ContainersList } from '../src/Modules/Containers/Containers';
import { FlightsCreate, FlightsEdit, FlightShow, FlightsList } from '../src/Modules/Flights/Flights';
import { InvoiceCreate, InvoiceEdit, InvoiceList } from '../src/Modules/Invoices/Invoices';
import { NoticesCreate, NoticesEdit, NoticesList } from '../src/Modules/Notices/Notices';
import { ShipmentCreate, ShipmentEdit, ShipmentList, ShipmentShow } from '../src/Modules/Shipments/Shipments';
import { UserCreate, UserEdit, UserList, UserShow } from '../src/Modules/Users/users';
import { WarehouseCreate, WarehouseEdit, WarehouseList } from '../src/Modules/Warehouse/Warehouse';
import CustomLayout from './Components/CustomLayout';
import CustomLoginPage from './CustomLoginPage';
import Dashboard from './Dashboard/Dashboard';
import { theme } from './Theme/theme';
import CustomAppBar from './Components/CustomAppBar'
import CustomMenu from './Components/CustomMenu';
import DashboardCard from './Components/DashboardCard';

const useSidebarStyles = makeStyles({
  drawerPaper: {
  
       backgroundColor: '#000',
    color: '#ed8b00'
    
   
}, 


});

const MySidebar = props => {
  const classes = useSidebarStyles();
  return (
      <Sidebar classes={classes} {...props} />
  );
};

const MyLayout = props => <Layout {...props} menu={CustomMenu} appBar={CustomAppBar} sidebar={MySidebar} />

const App = () => {

  // const [currentUser,setCurrentUser] = React.useState()
  const [loading,setLoading] = React.useState(false)
  // const [isAdmin,setIsAdmin] = React.useState(false)

  const accessProvider ={
    ...authProvider,
      // Wrap the login and check for custom claims
  login: async (params) => {
    const user = await authProvider.login(params);
    // getPermissions is how when get the custom claims for the logged in user
    const claims = await authProvider.getPermissions();
    
    const isAdmin = claims?.admin
    // alert(isAdmin);
    if (isAdmin ) {
      return user;
    }
    // Make sure user is logged out, if not an admin
    await authProvider.logout()
    throw new Error("Access Denied");
  },
  }

  // React.useEffect(()=>{
  //   const user =  authProvider.checkAuth()
    
  //   user.then((data)=>{
  //     setLoading(false)
  //   setCurrentUser(data)  
  //   const claims =  authProvider.getPermissions();
  //   claims.then(res =>{
  //     setIsAdmin(JSON.parse(res.admin))
     
  //   })
  //   .catch(error=>{
  //     setLoading(false)
  //     console.log(error);
  //   })


  //   })
  //   .catch(error=>{
  //     setLoading(false)
  //     console.log(error);
  //   })
  //   .finally(() => setLoading(false))


  // },[])
  return (
     <>
   {  !loading  && <>
     <Admin title="Takers Cargo"  layout={MyLayout} loginPage={CustomLoginPage} authProvider={accessProvider}  theme={theme} dataProvider={dataProvider}>
     
          <Resource icon={AccountCircleOutlined} name="Users" show={UserShow}  list={UserList} edit={UserEdit} create={UserCreate}/>
          <Resource icon={BookOutlined} name="Bookings" show={ShipmentShow} list={ShipmentList} edit={ShipmentEdit} create={ShipmentCreate}/>
          <Resource icon={FlightTakeoffOutlined} name="Flights" show={FlightShow} list={FlightsList} edit={FlightsEdit} create={FlightsCreate}/>
          {/* <Resource name="Shipments" list={ShipmentList} edit={ShipmentEdit} create={ShipmentCreate}/> */}
          <Resource icon={LocalShippingOutlined} name="Containers" show={ContainerShow} list={ContainersList} edit={ContainersEdit} create={ContainersCreate}/>
          <Resource icon={ReceiptOutlined} name="Invoices" list={InvoiceList} edit={InvoiceEdit} create={InvoiceCreate}/>
          <Resource icon={MapOutlined} name="Warehouse" list={WarehouseList} edit={WarehouseEdit} create={WarehouseCreate}/>
          <Resource icon={NotificationsActiveOutlined} name="Notices" list={NoticesList} edit={NoticesEdit} create={NoticesCreate}/>
          <Resource icon={AddBoxOutlined} name="Bailment" list={BailmentList} edit={BailmentEdit} create={BailmentCreate}/>
          </Admin>
   </>
        }
     </>
      
  )}


export default App;