import {
    FirebaseAuthProvider,
    FirebaseDataProvider,
    
  } from 'react-admin-firebase';
  
  const config = {
    apiKey: "AIzaSyABWGhE9MFRwT3b0ZuY25BArWd_KqvTbAI",
    authDomain: "takers-cargo.firebaseapp.com",
    databaseURL: "https://takers-cargo-default-rtdb.firebaseio.com",
    projectId: "takers-cargo",
    storageBucket: "takers-cargo.appspot.com",
    messagingSenderId: "495360546641",
    appId: "1:495360546641:web:671ec7306f7b4281c5a570",
    measurementId: "G-FPZPC725L6"
  
};
  
  // All options are optional
  const options = {
    watch: []
  }
  
  export const dataProvider = FirebaseDataProvider(config, options);
  export const authProvider = FirebaseAuthProvider(config, options);
//   const firebaseRealtime = FirebaseRealTimeSaga(dataProvider, options);