import { Chip } from '@mui/material';
import { ReferenceField, useRecordContext } from 'react-admin';

const CustomTag = ({ label }) => {
    const record = useRecordContext();
    return record ? (
      <ReferenceField label={label} source={record?.method === "sea" ?"containerId" : "flightId"} reference={record?.method === "sea" ?"Containers" : "Flights"} >
            <Chip  size={`small`} color={
            record?.status === `arrived` ? `success` :
            record?.status === `in-transit` ? `secondary`:
            record?.status === `pending` ? `info` :
            record?.status === `arrived` && record?.paymentStatus === `paid` ? `dark` :
            `info`

        } label={record?.status === `arrived` && record?.paymentStatus === `paid`? `Received` : record?.status} />
      </ReferenceField>
    ) : null;
}


export default CustomTag;
