import * as React from "react";
import { Create, Datagrid, Edit, List, SimpleForm, TextField, TextInput } from 'react-admin';
import Tag from '../../Components/Tag';
const Title = ({ record }) => {
        return <span>Invoicet Details {record ? `#${record.id}` : ''}</span>;
    };

    const Filters = [
        <TextInput source="q" label="Search" alwaysOn />,
        // <SelectInput optionText="Invoicet" label="Invoicet" />
        
    ];    

export const InvoiceList = props => (
    <List filters={Filters} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="origin" />
            <TextField source="destination" />
            <TextField source="depatureDate" />
            <TextField source="cost" />
            <TextField source="containerId" />
            <Tag source="status" />

        </Datagrid>
    </List>
);

export const InvoiceEdit = props => (
    <Edit title={<Title />} {...props}>
        <SimpleForm>
            
        <TextField source="id" />
            <TextInput source="origin" />
            <TextInput source="destination" />
            <TextInput source="depatureDate" />
            <TextInput source="cost" />
            <TextInput source="containerId" />
            <Tag source="status" />

        </SimpleForm>
    </Edit>
);

export const InvoiceCreate = props => (
      <Create {...props}>
        <SimpleForm>
            
        <TextInput disabled initialValue={`I`+ Date.now().toString()} source="id" />
            <TextInput source="origin" />
            <TextInput source="destination" />
            <TextInput source="depatureDate" />
            <TextInput source="cost" />
            <TextInput source="containerId" />
            <Tag source="status" />
            </SimpleForm>
      </Create>
  );