import { jsPDF } from "jspdf";
import { ArrayField, ArrayInput, Create, Datagrid, DateField, DateInput, DeleteButton, Edit, EditButton, List, NumberInput, RadioButtonGroupInput, ReferenceManyField, Show, ShowButton, SimpleForm, SimpleFormIterator, SimpleShowLayout, TextField, TextInput, useDataProvider, useNotify } from 'react-admin';
import Tag from '../../Components/Tag';

import 'jspdf-autotable';
import Route from "../../Components/Route";
import Table from "../../Components/Table";

const doc = new jsPDF({
    orientation: 'l'
});

const Title = ({ record }) => {
    return <span>Containers Details {record ? `#${record.id}` : ''}</span>;
};

const Filters = [
    <TextInput source="name" label="Search" alwaysOn />,
    // <SelectInput optionText="Containerst" label="Containerst" />

];

export const ContainerShow = (props) => {
    // const { data,ids, loading, error } = useGetManyReference('Bookings','containerId',props.id,{},{},{},'Containers',{});


    return (
        <Show  aside={<Route />} {...props} >
           
            <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="origin.country" label={'Origin Country'} />
            <TextField source="origin.city" label={'Origin City'} />
            <TextField source="destination.country" label={'Destination Country'} />
            <TextField source="destination.city" label={'Destination City'} />
            <DateField source="depatureDate" />
            <DateField source="expectedArrivalDate" label={`Arrival Date`}/>

            <Table target={`containerId`} />
            
            <ReferenceManyField  label="Bailments" reference="Bookings" target="containerId">
                <Datagrid fullWidth>
                    <TextField source="id" label={`Tracking Number`} />
                    <TextField label={`Fullname`} source="destination.fullname" />
                    <TextField label={`Address`} source="destination.detailedAddress" />
                    <TextField label={`Phone`} source="destination.phone" />

                    <TextField label={`Warehouse`} source="origin.fullname" />

                    <ArrayField source="bailment" label={`List of packages`}>
                <Datagrid>
                    <TextField source="expressNumber" label={`Express Number`} />
                    <TextField source="type" label={`type`} />
                    <TextField source="quantity" label={`quantity`} />
                    {/* <TextField source="unit" label={`unit`} />
                    <TextField source="price" label={`price`} /> */}
                </Datagrid>
            </ArrayField>
                </Datagrid>
                
            </ReferenceManyField>
            <button onClick={()=>{doc.autoTable({html:`#table`});doc.save(`${props.id}.pdf`)}}>print</button>
            </SimpleShowLayout>
        </Show>
    )
};
export const ContainersList = props => (
    <List filters={Filters} {...props}   sort={{ field: 'createdate', order: 'DESC' }}>
        <Datagrid rowClick="edit">
        <TextField source="id" />
            <TextField source="name" />
            <TextField source="origin.country" label={'Origin'} />
            <TextField source="destination.country" label={'Destination'} />
            <DateField source="depatureDate" />
            <DateField source="expectedArrivalDate" label={`Arrival Date`}/>
            <TextField source="cbm" />
            <Tag source="status" />
            <ShowButton label={false} />
            <EditButton label={false} />
            <DeleteButton label={false} />

        </Datagrid>
    </List>
);

export const ContainersEdit = props => {

    const dataProvider = useDataProvider()

    const notify = useNotify();
    // const refresh = useRefresh();
    // const redirect = useRedirect();

    const onSuccess = ({ data }) => {
        notify(`Searching for any inclusive shipments `)
        const status = data.status
        const expectedArrivalDate =data.expectedArrivalDate

        dataProvider
            .getManyReference('Bookings', {
                pagination: { page: 1, perPage: 100000 },
                target: 'containerId',
                id: data.id,
                sort: { field: 'creationDate', order: 'DESC' },

            })
            .then(({ data }) => {

                const shipmentList = data.map(each => { return (each.id) })

                dataProvider.updateMany('Bookings', {
                    ids: shipmentList,
                    data: { status: status , expectedArrivalDate: expectedArrivalDate },
                })
                    .then( () =>{
                        // Send Email to recepient's email
                            // If status is arrived
                            if(status === 'arrived'){

                                // fetch('https://www.takerscargo.com/api/arrivalEmail', {
                                //     method: 'POST', // or 'PUT',
                                //     headers:{
                                //         'Content-Type': 'application/json',

                                //         'Access-Control-Allow-Origin':'*'
                                //     },
                              
                                //     body: JSON.stringify({emailContent:data}),
                                //   })

                                //     .then(() => notify('Successfully sent email notification to receivers'))
    
                                //     .catch(err => notify(err.message))

                           
                                
                           
                            }
                            else{
                                notify('Successfully updated shipments')
                            }
                    }
                        
                    )
                    .catch(err => notify(err.message))



            }
            )
            .catch(err => notify(err.message))




    };

    const onFailure = (error) => {
        notify(`Could not edit post: ${error.message}`);
        // redirect('/posts');
    };
    return (
        <Edit undoable={false} mutationMode="pessimistic" onSuccess={onSuccess} onFalure={onFailure} title={<Title />} {...props}>
            <SimpleForm>
<Tag source="status" />

                <TextField source="id"  />

                <TextInput source="name" />
        <TextInput source="origin.country" label={'Origin Country'} />
            <TextInput source="origin.city" label={'Origin City'} />
            <DateInput source="depatureDate" />
            <DateInput source="expectedArrivalDate" />
            <TextInput source="destination.country" label={'Destination Country'} />
            <TextInput source="destination.city" label={'Destination City'} />
            
            <NumberInput label={"CBM"} source={'cbm'} />
                <RadioButtonGroupInput source="status" choices={[
                    { id: 'pending', name: 'Pending' },
                    { id: 'in-transit', name: 'In-transit' },
                    { id: 'arrived', name: 'Arrived' },
                ]} />
                <ArrayInput source={`waypoints`}>
                    <SimpleFormIterator>
                        <TextInput source={`location`} label={`Location`} />
                        <TextInput source={`description`} label={`Description`}/>
                        <DateInput source={`timestamp`}label={`Update Time`} />

                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    )
};

export const ContainersCreate = props => (
    <Create {...props}>
        <SimpleForm>

            <TextInput disabled initialValue={`C`+ Date.now().toString()} source="id" />
            <TextInput source="name" />
        <TextInput source="origin.country" label={'Origin Country'} />
            <TextInput source="origin.city" label={'Origin City'} />
            <DateInput source="expectedArrivalDate" />

            <TextInput source="destination.country" label={'Destination Country'} />
            <TextInput source="destination.city" label={'Destination City'} />
            
            <DateInput source="depatureDate" />
            <NumberInput source="cbm" />

            <RadioButtonGroupInput source="status" choices={[
                { id: 'pending', name: 'Pending' },
                { id: 'in-transit', name: 'In-transit' },
                { id: 'arrived', name: 'Arrived' },
            ]} />
         <ArrayInput source={`waypoints`}>
                    <SimpleFormIterator>
                        <TextInput source={`location`} label={`Location`} />
                        <TextInput source={`description`} label={`Description`}/>
                        <DateInput source={`timestamp`}label={`Update Time`} />

                    </SimpleFormIterator>
                </ArrayInput>
        </SimpleForm>
    </Create>
);