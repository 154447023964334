import React from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';

function Table({ target }) {
    const record = useRecordContext();
    const [bailment, setBailment] = React.useState([])

    const dataProvider = useDataProvider()

    React.useEffect(() => {
        if (record) {
            dataProvider
                .getManyReference('Bookings', {
                    pagination: { page: 1, perPage: 100000 },
                    target: target,
                    id: record.id,
                    sort: { field: 'creationDate', order: 'DESC' },

                })
                .then(({ data }) => {

                     data.map(each => { return (each.id) })
                    setBailment(data)





                }
                )
                .catch(err => console.log(err.message))
        }

    }, [record])
    return (
        <table style={{display: `none`}} id="table">
      
            <tr>
            <th>Tracking Number</th>
                <th>Express Number</th>
                <th>Fullname</th>
                <th>Phone</th>
                <th>Warehouse</th>
                <th>No. of Packages</th>
                <th>List of items</th>
            </tr>


            {
                bailment?.map(each => (
                    each?.bailment.map(item => {
                        return (
                            <>
                                <tr>
                                <td>{each?.trackingNumber}</td>
                                    <td>{item?.expressNumber}</td>
                                    <td>{each?.destination.fullname}</td>
                                    <td>{each?.destination?.phone}</td>
                                    <td>{each?.origin?.fullname}</td>
                                    <td>{each?.bailment.length}</td>
                                    <td>{item?.type}</td>
                                </tr>

                            </>

                        )
                    })
                ))
            }


        </table>
    );
}

export default Table;