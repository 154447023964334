import * as React from "react";
import {ArrayInput, SimpleFormIterator,NumberInput, Create, Datagrid, DeleteButton, Edit, EditButton, List, SimpleForm, TextField, TextInput, SelectInput } from 'react-admin';
import Tag from '../../Components/Tag';
const Title = ({ record }) => {
    return <span>Bailment Details {record ? `#${record.id}` : ''}</span>;
};

const Filters = [
    <TextInput source="q" label="Search" alwaysOn />,
    // <SelectInput optionText="Bailmentt" label="Bailmentt" />

];

export const BailmentList = props => (
    <List filters={Filters} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="category" />
            <TextField source="unit" />
            <TextField source="transport" />
            <Tag source="active" />
            <EditButton label={false} />
            <DeleteButton label={false} />
        </Datagrid>
    </List>
);

export const BailmentEdit = props => (
    <Edit title={<Title />} {...props}>
        <SimpleForm>

            <TextField source="id" />
            <TextInput source="category" />
            <TextInput source="unit" />
            <SelectInput label={`Prices for`} source="transport" choices={[
    { id: 'sea', name: 'sea' },
    { id: 'air', name: 'air' }
            ]} />
            <Tag source="active" />
            <ArrayInput source="items">
    <SimpleFormIterator>
        <TextInput source="itemName" label={`Item Name`}/>
        <NumberInput source="rate" label={`Rate`} />
    </SimpleFormIterator>
</ArrayInput>

        </SimpleForm>
    </Edit>
);

export const BailmentCreate = props => (
    <Create {...props}>
        <SimpleForm>

            <TextInput disabled initialValue={`B`+ Date.now().toString()} source="id" />
            <TextInput source="category" />
            <TextInput source="unit" />
            <SelectInput label={`Prices for`} source="transport" choices={[
    { id: 'sea', name: 'sea' },
    { id: 'air', name: 'air' }
            ]} />
            <Tag source="active" />
            <ArrayInput source="items">
    <SimpleFormIterator>
        <TextInput source="itemName" label={`Item Name`}/>
        <NumberInput source="rate" label={`Rate`} />
    </SimpleFormIterator>
</ArrayInput>
        </SimpleForm>
    </Create>
);