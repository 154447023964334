import { Box } from '@mui/material';
import React from 'react'
import { NumberInput, RadioButtonGroupInput, SelectInput, TextInput, useNotify, useRecordContext } from 'react-admin';
import { useFormState } from 'react-final-form';


export default function EditBailmentInputFields({bailment,transport,source,scope,record}) {
    const [payload, setPayload] = React.useState([]);
    const [itemNameList, setItemNameList] = React.useState([]);
    const [filteredBailmentList, setFilteredBailmentList] = React.useState([]);
    const [currentCategory, setCurrentCategory] = React.useState(record.category);
    const [selectedItem, setSelectedItem] = React.useState(record.item);
    const [rate, setSelectedRate] = React.useState(1);
    const [quantity,setQuantity] = React.useState(record.quantity);
    const [price,setPrice] = React.useState(record.price);
  




    let l = []
    // Assign price prop to scopeFormData i.e scope
    // This has been the best solution to add the price prop
    // of each bailment to the formData 


    const getPrice = async (rate,quantity) => (await  rate*quantity)

//     React.useEffect(() => {
//       if(record){
//       setPrice(record.price)
//    }
//     }, []);

//     React.useEffect(() => {

//         l = []
        
//         const choiceList = bailment?.filter(each =>{
//                 l.push(each)
//                  return transport === each.transport && each
//  })
        
//          const itemList = l.filter((each,i) => {
            
//             return each.category === currentCategory && each?.items
//          })

     
//         setPayload(choiceList)
//         setFilteredBailmentList(l)
//         setItemNameList(itemList[0]?.items)
   
//     }, [transport,currentCategory]);

//     React.useEffect(()=>{
//         // Find selectedItem object
//         // in the itemList array

//         let itemSelected = itemNameList.find(o => o.itemName === selectedItem);
//         setSelectedRate(parseFloat(itemSelected?.rate))

  
//     },[selectedItem])


//     React.useEffect(()=>{

//         if(quantity && rate){
//             // Calculate bailment price
//             getPrice(rate,quantity)
//             .then(res =>{ 

//              Object.assign(scope,{price:res})

//               setPrice(rate*quantity)
//           })
//           .catch(
//             e => alert(e.message)
//           )

            
           
     
//         }
  
//       },[quantity])

   

  return (
    <>
    {
        record && transport &&
        <Box>
<SelectInput onChange={ e => setCurrentCategory(e.target.value)}  source={source('category')} optionValue={'category'} optionText={'category'}  label={'Category/Type'} choices={payload} /> 
<SelectInput   onChange={ e => setSelectedItem(e.target.value)} source={source('item')} label={`Item Name`} optionValue={'itemName'} optionText={'itemName'}  choices={itemNameList} />
<NumberInput  onChange={ e => setQuantity(parseFloat(e.target.value))} source={source('quantity')} label={`quantity`} />
<NumberInput onChange={e => setPrice(rate*quantity)} source={source('price')} label={`price`} />

    </Box>  
    }
    </>)
}
