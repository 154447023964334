// in src/Dashboard.js
import { Box, Grid } from "@mui/material";
import { Chart as ChartJS, registerables } from 'chart.js';
import { format } from 'date-fns';
import * as React from "react";
import { ArrayField, BooleanField, Datagrid, DateField, ReferenceField, TextField, Title, useDataProvider, useGetList } from 'react-admin';
import { Doughnut } from 'react-chartjs-2';
import Bar from "../Components/Bar";
import CustomField from "../Components/CustomField";
import CustomTag from "../Components/CustomTag";
import DashboardCard from "../Components/DashboardCard";
import MonthlyChart from '../Components/MonthlyChart';
import { Bookmark ,AccountCircle, CreditCard , CollectionsBookmark } from '@material-ui/icons';

ChartJS.register(...registerables);

const BookingsTable = (props) =>{
  return(
    <ArrayField source="Bookings" label={`List of packages`}>

    <Datagrid  >
                        <TextField source="id" label={`Tracking Number`} />
                        <TextField label={`Fullname`} source="destination.fullname" />
                        <TextField label={`Address`} source="destination.detailedAddress" />
                        <TextField label={`Phone`} source="destination.phone" />


                        <TextField label={`Warehouse`} source="origin.fullname" />

                         
                            </Datagrid>
                            </ArrayField>
  )
};
 
const BarGraph = ({arr}) => { 

  const total =    arr.reduce((previousValue, currentValue) => previousValue + currentValue.value,0);

  
  return (arr.map(each =>{
  
  return(<>
 <Bar label={each.name} value={parseInt(100 * each.value/total)}  />
  </>)})) }

const Dashboard =  () => {
    const dataprovider = useDataProvider()
    const [ bookings,setBookings] = React.useState([]);
    const [ users,setUsers] = React.useState([]);
    const [bailmentGroup,setBailmentGroup] = React.useState([])
    const [loading,setLoading] = React.useState(true)
    const currentSort = { field: 'creationDate', order: 'DESC' };
    const { ids, data, total, loaded } = useGetList(
      'Bookings',
      { page: 1, perPage: 5 },
      currentSort
      );
  // const aMonthAgo = React.useMemo(() => subDays(startOfDay(new Date()), 30), []);

//   const { data ,ids ,total} = useGetList( 'Bookings',{
    
//    pagination: { page: 1, perPage: 50 },
//    sort:  { field: 'creationDate', order: 'DESC' },
//    filter: { creationDate: `1652378099069` }

// }
  
  
//   );
  
  // Group array of objects by `category` prop
  // Into array of grouped objects
  
  function groupBy(objectArray, property) {
    return Object.values(objectArray.reduce((c, {category}) => {
      c[category] = c[category] || {name: category,value: 0};
      c[category].value++;
      return c;
    }, {}))
  }
React.useEffect(()=>{
  dataprovider.getList( 'Bookings',{
  
      pagination: { page: 1, perPage: 10000 },
      sort:  { field: 'creationDate', order: 'DESC' },
          //  filter: { creationDate: aMonthAgo.toISOString() }
   
   }
     
     
     )
     .then(response =>{
         setBookings(response.data.map(each =>each))
         setLoading(false)
     })
     dataprovider.getList( 'Users',{
     
         pagination: { page: 1, perPage: 10000 },
         sort:  { field: 'creationDate', order: 'DESC' },
             //  filter: { creationDate: aMonthAgo.toISOString() }
      
      }
        
        
        )
        .then(response =>{
            setUsers(response.data.map(each =>each))
            setLoading(false)
        })
},[])
 const topBailment = React.useMemo(()=>{

  let itemList = []
  bookings.map(each => (each.bailment.map(item =>{
     itemList.push({category:item.category})
     return null
    
    })))
    // itemList.reduce((a, c) => (a[c] = (a[c] || 0) + 1, a), Object.create(null));
  const itemGroups = groupBy(itemList,`category`)
  setBailmentGroup(itemGroups)

 },[bookings])
  const aggregation = React.useMemo(() => {
    const today = new Date().getTime();
    

      if (!bookings) return {};

      // Aggregate shipments
      const aggregations = bookings?.reduce(
        (stats, order) => {
            if (order.status === 'pending') {
                // stats.revenue += order.total;
                
                stats.pendingOrders++;
            }
            if (order.status === 'in-transit') {
                stats.intransitOrders++;
            }
            if (order.status === 'arrived') {
                stats.arrivedOrders++;
            }
            if (order.status === 'arrived' && order.paymentStatus === 'paid') {
                stats.paidReceivedOrders++;
              
                stats.revenue+=parseFloat(order.price);
            }
            if (format(new Date(order.creationDate), 'D/M/YYYY') === format(new Date(today), 'D/M/YYYY')) {
                stats.todaysOrders++;
            }
            if (order.inWarehouse && order.status === `pending`) {
                stats.inWarehouse++;
            }
            if (!order.inWarehouse && order.status === `pending`) {
                stats.notInWarehouse++;
            }
            return stats;
        },
        {
           
            // newShipments: 0,
            pendingOrders: 0,
            intransitOrders:0,
            arrivedOrders:0,
            paidReceivedOrders:0,
            todaysOrders: 0,
            inWarehouse:0,
            notInWarehouse: 0,
            revenue:0
        
        }
    );
      return {
         
         
          newShipments: aggregations.newShipments,
          pendingOrders: aggregations.pendingOrders,
                    intransitOrders:aggregations.intransitOrders,
            arrivedOrders:aggregations.arrivedOrders,
            paidReceivedOrders:aggregations.paidReceivedOrders,
            todaysOrders:aggregations.todaysOrders,
            inWarehouse: aggregations.inWarehouse,
            notInwarehouse: aggregations.notInWarehouse,
               totalRevenue: aggregations.revenue,
       
      };

      // Top Bailments

  }, [bookings]);

  const { todaysOrders,notInwarehouse , totalRevenue, inWarehouse ,pendingOrders ,intransitOrders ,arrivedOrders ,paidReceivedOrders  } = aggregation;
  const doughnut = {
    labels: ['Pending','In-transit','Arrived','Received & Paid'],
    datasets: [
        {
          id: 1,
          label: 'In-transit',
          data: [pendingOrders,intransitOrders,arrivedOrders,paidReceivedOrders],
          backgroundColor: [
   
            '#000',
            'rgba(255, 206, 86)',
            'rgb(237 139 0)',
            'rgb(204 172 126)',
            
          ],
         
        },

    ],
  }


  return (
    <Box style={{backgroundColor:`#c4c4c410`,padding:`2%` ,height:`100%`}} >
    <Title title="Takers Cargo Dashboard"  />
  
    <Grid container spacing={2}>
    <Grid item xs={12}>

<div style={{display: 'flex'}}>
  <DashboardCard icon={<CollectionsBookmark size={16} color={'#000'}/>} title={`Total Bookings`} data={ bookings && bookings.length} />

<DashboardCard icon={<CreditCard size={16} color={'#000'}/>} title={`Total Revenue`} data={`$ ${parseInt(totalRevenue)}`} />
<DashboardCard icon={<Bookmark size={16} color={'#000'}/>} title={`Today's Booking`} data={ todaysOrders}  />
<DashboardCard icon={<AccountCircle size={16} color={'#000'}/>} title={`Total Users`} data={ users?.length}  />

  </div>

  </Grid>
    <Grid item xs={8}>

<DashboardCard title={`Shipment History`} component={<MonthlyChart shipments={bookings} />} />
      
    </Grid>
    
    <Grid item xs={4}>
    <DashboardCard title={`Shipment Analytics`} component={<Doughnut  data={doughnut} />} />


    </Grid>
   
    <Grid item xs={3}>
          <DashboardCard title={`Pending Dispatch`} data={ pendingOrders} />

 
          <DashboardCard title={`Received Bookings`} data={` ${inWarehouse} out of ${notInwarehouse}`} />


    </Grid>
    <Grid item xs={3}>
    <DashboardCard title={`Top Bailment`} component={<BarGraph arr={bailmentGroup} />} />

 
    </Grid>
    <Grid item xs={6}>
    <DashboardCard title={`Current Shipments`} data={
      <Datagrid
      basePath=""
      currentSort={currentSort}
     data={data}
     ids={ids}
     selectedIds={[]}
     loaded={loaded}
     total={total}
   
      >
   
   <TextField source="trackingNumber" />
   <ReferenceField source="userId" reference="Users">
                <TextField source="fullname" />
            </ReferenceField>
          
            <DateField source="creationDate" label={`Time`} />
 <BooleanField  source="inWarehouse" label={"Warehouse"} valueLabelTrue={`Yes`} valueLabelFalse={`No`}    />

            <CustomTag label={`Status`} />
            
      </Datagrid>
    } />







    <BookingsTable/>

    </Grid>
  </Grid>
    </Box>
)};

export default Dashboard