import * as React from "react";
import {TextField, ReferenceField, useRecordContext } from 'react-admin';

const CustomField = ({ label,source }) => {
    const record = useRecordContext();
    return record ? (
      <ReferenceField label={label} source={record?.method === "sea" ?"containerId" : "flightId"} reference={record?.method === "sea" ?"Containers" : "Flights"} >
            <TextField  size={`small`} source={source} />
      </ReferenceField>
    ) : null;
}


export default CustomField;
