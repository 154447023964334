import * as React from "react";
import { ArrayField, BooleanField, Create, Datagrid, DeleteButton, Edit, EditButton, EmailField, List, RadioButtonGroupInput, ReferenceManyField, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TextInput } from 'react-admin';
const Title = ({ record }) => {
        return <span>User Details {record ? `#${record.id}` : ''}</span>;
    };

    const Filters = [
        <TextInput source="fullname" label="Full Name" alwaysOn />,
        <TextInput source="phone" label="Phone" alwaysOn />,
        // <SelectInput optionText="user" label="User" />
        
    ];    

    export const UserShow = (props) => {

    
    
        return (
            <Show {...props}>
                <SimpleShowLayout >
                    
                <TextField source="id" />
            <TextField source="fullname" />
            <TextField source="phone" />
            <EmailField source="email" />
            <BooleanField source="emailVerified" />
            <BooleanField source="verificationEmailSent" />
            <TextField source="role" />
            <ReferenceManyField target={`userId`} reference={`Bookings`}>
                <Datagrid>
              
                <TextField source="id" label={`Tracking Number`} />
                <TextField label={`Address`} source="destination.detailedAddress" />
                <TextField label={`Phone`} source="destination.phone" />
                <ArrayField source="bailment" label={`List of packages`}>
                            <Datagrid >
                                <TextField source="expressNumber" label={`Express Number`} />
                    <TextField source="type" label={`type`} />
                    <TextField source="quantity" label={`quantity`} />
                    <TextField source="unit" label={`unit`} />
                    <TextField source="price" label={`price`} />
                            </Datagrid>
                        </ArrayField>

                </Datagrid>
            </ReferenceManyField>
     
            
                </SimpleShowLayout>
            </Show>
        )
    }    

export const UserList = props => (
    <List filters={Filters} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="fullname" />
            <TextField source="phone" />
            <EmailField source="email" />
            <TextField source="role" />
            {/* <Tag source="status" /> */}
                         
            <ShowButton label={false} />
            <EditButton label={false} />
            <DeleteButton label={false} />
        </Datagrid>
    </List>
);

export const UserEdit = props => (
    <Edit title={<Title />} {...props}>
        <SimpleForm>
            
        <TextInput source="id" />
            <TextInput source="fullname" />
            <TextInput source="phone" />
            <TextInput source="email" />
                        <RadioButtonGroupInput source="role" choices={[
                    { id: 'user', name: 'user' },
                    { id: 'admin', name: 'admin' },
                ]} />
            <TextInput source="status" />

        </SimpleForm>
    </Edit>
);

export const UserCreate = props => (
      <Create {...props}>
        <SimpleForm>
            
            <TextInput disabled initialValue={`U`+ Date.now().toString()} source="id" />
                <TextInput source="fullName" />
                <TextInput source="phone" />
                <TextInput source="email" />
                            <RadioButtonGroupInput source="role" choices={[
                    { id: 'user', name: 'user' },
                    { id: 'admin', name: 'admin' },
                ]} />
                <TextInput source="status" />

            </SimpleForm>
      </Create>
  );