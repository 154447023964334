import { BooleanInput, Create, Datagrid, DateField, DateInput, DeleteButton, Edit, EditButton, FileField, FileInput, List, RadioButtonGroupInput, SimpleForm, TextField, TextInput } from 'react-admin';
const Title = ({ record }) => {
    return <span>Notices Details {record ? `#${record.id}` : ''}</span>;
};

const Filters = [
    <TextInput source="name" label="Search" alwaysOn />,
    // <SelectInput optionText="Noticest" label="Noticest" />

];

export const NoticesList = props => (
    <List filters={Filters} {...props}   sort={{ field: 'createdate', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="type" />
            <DateField source="lastupdate" />
            <TextField source="active" />
            <EditButton label={false} />
            <DeleteButton label={false} />
        </Datagrid>
    </List>
);

export const NoticesEdit = props => (
    <Edit title={<Title />} {...props}>
          <SimpleForm>

<TextInput disabled initialValue={`N`+ Date.now().toString()} source="id" />
    <TextInput source="name" />
    <RadioButtonGroupInput label={`type`} source={`type`} choices={[

{ id: 'announcement', name: 'announcement' },
{ id: 'banner', name: 'banner' },
]} />
    <TextInput source="content" />

    <FileInput source="file" label="File" accept="" >
<FileField source="src" title="title" />
</FileInput>
    <DateInput source="lastupdate" />
    <BooleanInput  source="active" label={"Active"} valueLabelTrue={`Yes`} valueLabelFalse={`No`}    />
</SimpleForm>
    </Edit>
);

export const NoticesCreate = props => (
    <Create {...props}>
        <SimpleForm>

        <TextInput disabled initialValue={`N`+ Date.now().toString()} source="id" />
            <TextInput source="name" />
            <RadioButtonGroupInput label={`type`} source={`type`} choices={[

{ id: 'announcement', name: 'announcement' },
{ id: 'banner', name: 'banner' },
]} />
            <TextInput source="content" />
        
            <FileInput source="file" label="File" accept="" >
  <FileField source="src" title="title" />
</FileInput>
            <DateInput source="lastupdate" />
            <BooleanInput  source="active" label={"Active"} valueLabelTrue={`Yes`} valueLabelFalse={`No`}    />
        </SimpleForm>
    </Create>
);