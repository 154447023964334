import * as React from "react";
import { useRecordContext } from 'react-admin';
import {Chip} from '@mui/material'

const Tag = ({ source }) => {
    const record = useRecordContext();
    return record ? (
        <Chip  size={`small`} color={
            record[source] === `arrived` ? `success` :
            record[source] === `in-transit` ? `secondary`:
            record[source] === `pending` ? `info` :
            `info`

        } label={record[source]} />
    ) : null;
}

export default Tag;
